

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, IForm,
} from '@/modules/scheduled-reports/interfaces';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import loop24 from '@/modules/common/filters/loop-24.filter';
import MarketsService, { MarketsServiceS } from '../markets.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(MarketsServiceS) marketsService!: MarketsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    readonly dataType = DATA_TYPE.MARKETS;

    form: IForm = {} as IForm;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            dateRange: {
                options: [30, 60],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    private defineCompsetFilter(filters: IFilterItem[]) {
        const { compsets, currentCompset } = this.compsetsService;

        const options = (compsets || [])
            .map(compset => ({
                name: compset.name,
                value: compset.id,
            }));

        const defaultValue = currentCompset
            ? currentCompset.id
            : '';

        const value = String(this.form.filters?.compset || defaultValue);

        filters.push({
            name: 'compset',
            label: 'Comp Set',
            value,
            options,
            disableOnEdit: true,
        });
    }

    private definePosFilter(filters: IFilterItem[]) {
        const { pos } = this.documentFiltersService.settings;
        const { posMarketItems: options } = this.documentFiltersService;

        const defaultValue = pos || options?.[0]?.value || '';
        const value = String(this.form.filters?.pos || defaultValue);

        filters.push({
            name: 'pos',
            label: 'POS',
            value,
            options,
            disableOnEdit: false,
        });
    }

    private defineProviderFilter(filters: IFilterItem[]) {
        const { filters: values = {} } = this.form;
        const { settings } = this.marketsService;

        const compset = this.compsetsService.getCompset(values.compset as string)
            || this.compsetsService.currentCompset;
        const providers = compset?.marketProviders || [];

        const defaultValue = providers.includes(settings.provider || providers[0])
            ? settings.provider || providers[0]
            : providers[0];

        let value = String(values.providers || defaultValue);
        value = providers.includes(value) ? value : defaultValue;

        const options = this.providersService.toItemsList(providers);

        values.providers = value;

        filters.push({
            name: 'providers',
            label: 'Source',
            value,
            options,
            disableOnEdit: false,
        });
    }

    private defineLosFilter(filters: IFilterItem[]) {
        const { settings: { los } } = this.documentFiltersService;
        const options = DEFAULT_LOS.map(los => ({
            value: los,
            name: `LOS${los}`,
        }));

        const defaultValue = los || options[0]?.value;
        const value = Number(this.form.filters?.los || defaultValue);

        filters.push({
            name: 'los',
            label: 'LOS',
            value,
            options,
            disableOnEdit: false,
        });
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        if (this.marketsService.isLoading) {
            return filters;
        }

        this.defineCompsetFilter(filters);
        this.definePosFilter(filters);
        this.defineProviderFilter(filters);
        this.defineLosFilter(filters);

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
